<template>
  <div>
    <analytics-company-percent-chart
      :option="temp"
      :data="series"
      :legend="dataList"
    />
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        class="custtom-header pr-1"
        @clickExportExcel="dowloadFile"
        @search="search($event)"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
      />
    </div>
  </div>

</template>

<script>
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import AnalyticsCompanyPercentChart from './components/AnalyticsCompanyPercentChart.vue'
import PercentDonutOption from '../configChart/PercentDonutOption'
import MyPagination from '@/components/pagination/MyPagination.vue'
import store from '../../store'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constansts/ConstanstsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    AnalyticsCompanyPercentChart,
  },
  data() {
    return {
      option: PercentDonutOption,
      series: [],
      // ==dữ liệu bảng
      dataList: [],
      columns: [
        {
          label: 'CÁC LOẠI TAI NẠN LAO ĐỘNG',
          field: 'name',
        },
        {
          label: 'SỐ LƯỢNG LAO ĐỘNG',
          field: 'value',

        },
      ],
      temp: {
        chart: {
          type: 'donut',
          fontFamily: 'Montserrat',
        },
      },
      legend: [],
      nameChart: [],
      // ==phân trang
      urlQuery: {
        search: '',
      },
      currentPage: 10,
    }
  },

  created() {
    this.fetchData(this.urlQuery)
  },

  methods: {
    async dowloadFile() {
      const name = 'CacTruongHopRuiRo.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL)
    },
    async fetchData(a) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_ACCIDENT, {
        params: a,
      })
      this.dataList = data.data
      this.series = this.dataList.map(e => e.value)
      this.nameChart = this.dataList.map(e => e.name)
      this.option.labels = this.dataList.map(e => e.name)
      this.temp = this.option
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.search = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.search = ''
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style>

</style>
